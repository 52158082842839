<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import General from '@/services/General';
    import Swal from "sweetalert2";
    import store from "@/state/store";
    import ModalAddOptIns from '@/components/modals/customers/modalAddOptIns';
    import ModalEditOptIns from '@/components/modals/customers/modalEditOptIns';
    import ExcelJS from "exceljs";


    export default {
        components: { Layout, PageHeader, ModalAddOptIns , ModalEditOptIns},
        page: {
            title: "Opt ins",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                title: "Opt ins",
                items: [
                    {
                        text: "Users",
                    },
                    {
                        text: "Opt ins",
                        active: true,
                        href: "/customers/optins",
                    },
                ],
                csrf_token: localStorage.getItem('csrf_token'),
                isBusy: false,
                error: null,
                showModal: false,
                modalData: {},
                link_id: null,
                tableData: [],
                totalRows: 0,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                OptInsData : {} ,
                data : {} ,
                flattenedData : {} ,
                sortBy: "timestamp",
                sortDesc: true,
                parentHasChildren : '' ,
                parent : null
            };
        },

        methods: {
            uniqueParents() {
                const uniqueSet = Array.isArray(this.item) ? new Set(this.item.map(data => data.name)) : new Set();
                return Array.from(uniqueSet);
            },
            callModalAddOptIns(data) {
                this.OptInsData = data;
                this.$bvModal.show("add_optins");
            },
            callModalOptInEdit(data){
                this.OptInsData = data;
                this.$bvModal.show("edit_optins");
            },
            findOptInName(id) {
                const optIn = this.tableData.find(opt => opt.id === id);
                return optIn ? optIn.name : id;
            },

            async getCustomerOptIns(status = 1) {
                try {
                    this.toggleBusy();
                    const data = `status=${status}`;
                    const response = await General.getCrmOptIns(data);

                    const flattenedData = this.flattenOptIns(response.data);

                    this.tableData = flattenedData;
                    this.totalRows = flattenedData.length;
                    this.toggleBusy();
                } catch (error) {
                    this.toggleBusy();
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tableData = [];
                    this.totalRows = 0;
                }
            },

            async downloadOptIns(data){
                try {
                    alert('Generating report. Please wait...')
                    const data1 = `attribute=${data}`;
                    const response = await General.getReportCrmOptIns(data1);

                    if (Array.isArray(response.data)) {
                        const ExcelJS = require('exceljs'); // Import ExcelJS
                        const workbook = new ExcelJS.Workbook();
                        const worksheet = workbook.addWorksheet('Download Crm OptIns');

                        const columnHeaders = [
                            { key: 'first_name', header: 'First Name', width: 40 },
                            { key: 'middle_name', header: 'Middle Name', width: 30 },
                            { key: 'last_name', header: 'Last Name', width: 30 },
                            { key: 'email', header: 'Email', width: 20 },
                            { key: 'date_of_birth', header: 'Date of birth', width: 20 },
                            { key: 'gender', header: 'Gender', width: 20 },
                            { key: 'phone_number', header: 'Phone Number', width: 20 },
                            { key: 'attribute', header: 'Attribute', width: 20 },
                            { key: 'created_on', header: 'Created On', width: 20 },
                        ];

                        worksheet.addRow(columnHeaders.map(column => column.header));
                        worksheet.getRow(1).font = { bold: true };

                        columnHeaders.forEach((column, index) => {
                            const cell = worksheet.getCell(String.fromCharCode(65 + index) + '1'); // A1, B1, C1, ...
                            cell.width = column.width;
                        });

                        response.data.forEach(item => {
                            const rowData = columnHeaders.map(column => item[column.key]);
                            worksheet.addRow(rowData);
                        });

                        const buffer = await workbook.xlsx.writeBuffer();

                        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = 'CrmOptInsReport.xlsx';
                        link.click();
                    }else {
                        alert('No data available for generating the report.');
                    }
                } catch (error) {
                    alert('An error occurred while fetching data from the API.');
                }


            },
            flattenOptIns(data) {
                const result = [];

                function flatten(item, parentName = null) {
                    result.push({
                        id: item.id,
                        parent: parentName,
                        name: item.name,
                        description: item.description,
                        type: item.type,
                        show_in_register: item.show_in_register,
                        timestamp: item.timestamp,
                    });

                    if (item.children && item.children.length > 0) {
                        item.children.forEach(child => flatten(child, item.name));
                    }
                }

                data.forEach(item => flatten(item));

                return result;
            },


            removeOptIns(item){

                const parentId = item.name;
                const parentHasChildren = this.tableData.some(optIn => optIn.parent === parentId );


                if (parentHasChildren) {
                    Swal.fire("Cannot Remove", "This Opt In has children. It can't be removed .", "warning");
                    return;
                }



                Swal.fire({
                    title: "Remove this Opt In?",
                    text: "This Opt In will be removed!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Remove!",
                }).then((result) => {
                    if (result.value) {
                        try {
                            General.deleteCrmOptIn(item.id)
                                .then((response) => {
                                    const res = response.data ? response.data : false;
                                    const error = response.data.error ? response.data.error : 'Failed';
                                    if(res){
                                        this.getCustomerOptIns()
                                        Swal.fire("Remove Opt In", "Opt In is successfully removed!", "success");
                                    }else{
                                        Swal.fire("Fail!", error, "warning");
                                    }
                                })
                        }catch(error){
                            this.error = error.response.data.error ? error.response.data.error : "";
                        }
                    }
                });
            },



            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
        },



        computed: {
            transformedTableData() {
                return this.tableData.map(optIn => {
                    return {
                        id : optIn.id ,
                        parent: optIn.parent ? this.findOptInName(optIn.parent) : null,
                        name: optIn.name,
                        description: optIn.description,
                        type: optIn.type,
                        show_in_register: Boolean(Number(optIn.show_in_register))
                    };
                });
            },
            tableFields() {
                return [
                    { key: "parent", label: "Parent", sortable: true },
                    { key: "name", label: "Key", sortable: true },
                    { key: "description", label: "Description", sortable: true },
                    { key: "type", label: "Type", sortable: true },
                    { key: "show_in_register", label: "Show in register", sortable: true ,  'class': 'text-center' },
                    { key: "action", label: "Action"},
                ];
            },
        },


        middleware: "authentication",

        created() {
            this.getCustomerOptIns();
        },
    };
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">

                    <div class="card-body">
                        <div class="row">

                           <div class="col-12 col-md-12  float-start">
                                <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light"
                                   @click="callModalAddOptIns(transformedTableData)">
                                    <i class="mdi mdi-plus me-2"></i>Add Opt Ins
                                </a>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-end"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="mb-0">
                            <b-table
                                    :busy="isBusy"
                                    :items="transformedTableData"
                                    :fields="tableFields"
                                    responsive
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    show-empty
                                    empty-text="No Data Found"
                                    @filtered="onFiltered"
                            >

                                <template v-slot:cell(show_in_register)="data">
                                    <div v-html="format_status(data.item.show_in_register ? 'yes' : 'no')"></div>
                                </template>


                                <template v-slot:cell(action)="data">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a href="javascript:void(0);" class="px-2 text-primary" title="Edit" @click="callModalOptInEdit(data.item)">
                                                <i class="uil uil-pen font-size-18"></i>
                                            </a>
                                        </li>

                                        <li class="list-inline-item">
                                            <a href="javascript:void(0);" class="px-2 text-danger"  title="Remove" @click="removeOptIns(data.item)">
                                                <i class="uil uil-trash-alt font-size-18"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a href="javascript:void(0);" class="px-2 text-primary"  title="Download Report" @click="downloadOptIns(data.item.name)">
                                                <i class="fa fa-download"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </template>

                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="totalRows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalAddOptIns :item="this.OptInsData" @onRefresh="getCustomerOptIns"></ModalAddOptIns>
        <ModalEditOptIns :item="this.OptInsData" @onRefresh="getCustomerOptIns"></ModalEditOptIns>

    </Layout>
</template>