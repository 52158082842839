<script>
    import { required, minLength } from "vuelidate/lib/validators";
    import General from '@/services/General';
    import validationMessages from '@/components/validations'

    export default {


        props: {
            item: {
                type: [Array, Object],
                default: null,
            },
        },

        components:{
            validationMessages
        },

        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                demandOptions: ['delete'],
                demand: '',
                source: 'dashboard',
                showModal: false,
                tryingToEdit: false,
                submitted: false,
                showInRegister: '1' ,
                parent: '',
                text : '' ,
                phone : '' ,
                email : '' ,
                uniqueSet: '' ,
                type : '' ,
                name : '' ,
                description : '' ,
                RemoveOptIns : '' ,
                selectedParent: null,


            };
        },
     validations : {
        name : {
            required,
            minLength: minLength(2)
        }  ,
         description : {
             required,
             minLength: minLength(2)
         }  ,
         showInRegister : {
             required
         } ,
         type : {
            required
         }

     } ,
        created() {
        },



        methods: {

            async addCrmOptIn(){
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()
                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {


                    const formData = {
                        name: this.name,
                        description: this.description,
                        show_in_register: this.showInRegister,
                        parent: this.parent,
                        type: this.type,
                    };

                    try {
                        await General.createCrmOptIn(formData).then((response) => {
                            this.successmsg("Request has been saved");
                            this.closeModal();
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg("Request not saved")
                        }).finally(() => {
                            this.refreshData()
                            this.tryingToEdit = false;
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Request not saved")
                    }
                }
                this.tryingToEdit = false;
            },


            resetProps(){
                this.name= '';
                this.description= '';
                this.type= '';
                this.parent = '' ;
                this.tryingToEdit = false;
                this.submitted = false;
            },

            uniqueParents() {
                if (this.item && Array.isArray(this.item)) {
                    const uniqueSet = new Set(this.item.map(data => data.name));
                    return Array.from(uniqueSet);
                }
                return [];
            },
            refreshData() {
                this.$emit('onRefresh')
            },
            closeModal() {
                this.showModal = false;
            },
        }
    };
</script>

<template>
    <b-modal v-model="showModal" id="add_optins" @hidden='resetProps()' title="Add Opt Ins" title-class="font-18" centered>
        <form @submit.prevent="addCrmOptIn">
            <b-form-group label="Choose Parent" label-for="formrow-function-input" class="mb-3">
                <template v-if="Array.isArray(item)">
                    <select v-model="parent" class="form-select">
                        <option v-for="data in item" :key="data.id" :value="data.id">{{data.name}}</option>
                    </select>
                </template>
            </b-form-group>

            <b-form-group label="Name" label-for="formrow-name-input" class="mb-3">
                <b-form-input
                        v-model.trim="name"
                        type="text"
                        id="name"
                        aria-describedby="name-feedback"
                        :class="{
                                          'is-invalid': submitted && $v.name.$error,
                                        }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Name'" :validationName="$v.name"></validationMessages>

            </b-form-group>
            <b-form-group label="Description" label-for="formrow-description-input" class="mb-3">
                <b-form-input
                        v-model.trim="description"
                        type="text"
                        id="description"
                        aria-describedby="name-feedback"
                        :class="{
                                          'is-invalid': submitted && $v.description.$error,
                                        }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Description'" :validationName="$v.description"></validationMessages>

            </b-form-group>
            <b-form-group label="Show in register" label-for="formrow-radio-input" class="mb-3">
                <div class="row">
                    <div class="col-6 ">
                        <b-form-radio v-model="showInRegister" value="1">Yes</b-form-radio>
                    </div>
                    <div class="col-6 ">
                        <b-form-radio v-model="showInRegister" value="0">No</b-form-radio>
                    </div>
                </div>
                <validationMessages v-if="submitted" :fieldName="'Show in register'" :validationName="$v.showInRegister"></validationMessages>

            </b-form-group>

            <b-form-group label="Chose Type" label-for="formrow-type-input" class="mb-3">
                <select v-model="type" class="form-select">
                    <option   value="email">Email</option>
                    <option   value="phone">Phone</option>
                    <option   value="text">Text</option>
                </select>
                <validationMessages v-if="submitted" :fieldName="'Chose Type'" :validationName="$v.type"></validationMessages>

            </b-form-group>

            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="addCrmOptIn" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Submit</b-button>
        </template>
    </b-modal>
</template>