<script>
    import { required, minLength } from "vuelidate/lib/validators";
    import General from '@/services/General';

    export default {


        props: {
            item: {
                type: [Array, Object],
                default: null,
            },
        },


        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                demandOptions: ['delete'],
                demand: '',
                source: 'dashboard',
                showModal: false,
                tryingToEdit: false,
                submitted: false,
                showInRegister: '1' ,
                parent: null,
                text : '' ,
                phone : '' ,
                email : '' ,
                uniqueSet: '' ,
                type : '' ,
                name : '' ,
                description : '' ,
                RemoveOptIns : '' ,
                selectedParent: null,
                id : '' ,
                show_in_register : '1' ,


            };
        },
        validations : {
            name : {
                required,
                minLength: minLength(2)
            }  ,
            description : {
                required,
                minLength: minLength(2)
            }  ,
            showInRegister : {
                required
            } ,
            type : {
                required
            }

        } ,
        created() {
        },



        methods: {


            syncData(){

                this.id = this.item.id;
                this.name = this.item.name;
                this.description = this.item.description;
                this.show_in_register = this.item.show_in_register ? '1' : '0';
                this.parent = this.item.parent;
                this.type = this.item.type;

            },



            async editCrmOptIn(){
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()
                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {

                    const id = this.id ;
                    const formData = {
                        id:this.id ,
                        description: this.description,
                        show_in_register: this.show_in_register,
                        type: this.type,
                    };

                    try {
                        await General.editCrmOptIn(id,formData).then((response) => {
                            this.successmsg("Opt In has been edit");
                            this.closeModal();
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg("Opt In not edit")
                        }).finally(() => {
                            this.refreshData()
                            this.tryingToEdit = false;
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Request not edit")
                    }
                }
                this.tryingToEdit = false;
            },

            uniqueParents() {
                if (this.item && Array.isArray(this.item)) {
                    const uniqueSet = new Set(this.item.map(data => data.name));
                    return Array.from(uniqueSet);
                }
                return [];
            },
            refreshData() {
                this.$emit('onRefresh')
            },
            closeModal() {
                this.showModal = false;
            },
        }
    };
</script>

<template>
    <b-modal  @shown="syncData" v-model="showModal" id="edit_optins"  title="Edit Opt Ins" title-class="font-18" centered>
        <form @submit.prevent="editCrmOptIn">
            <input type="hidden" name="id" v-model="item.id">
            <b-form-group label="Parent" label-for="formrow-function-input" class="mb-3">
                <div v-if="item">
                    <b-form-input :value="item.parent" readonly />
                </div>
                <div v-else>
                    <p>No parent chosen</p>
                </div>
            </b-form-group>
            <b-form-group label="Name" label-for="formrow-name-input" class="mb-3">
                <b-form-input
                        v-model.trim="name"
                        type="text"
                        id="name"
                        readonly
                        aria-describedby="name-feedback"
                        :class="{
                                          'is-invalid': submitted && $v.name.$error,
                                        }"
                >
                </b-form-input>

            </b-form-group>
            <b-form-group label="Description" label-for="formrow-description-input" class="mb-3">
                <b-form-input
                        v-model.trim="description"
                        type="text"
                        id="description"
                        aria-describedby="name-feedback"
                        :class="{
                                          'is-invalid': submitted && $v.description.$error,
                                        }"
                >
                </b-form-input>

            </b-form-group>
            <b-form-group label="Show in register" label-for="formrow-radio-input" class="mb-3">
                <div class="row">
                    <div class="col-6 ">
                        <b-form-radio v-model="show_in_register" value="1">Yes</b-form-radio>
                    </div>
                    <div class="col-6 ">
                        <b-form-radio v-model="show_in_register" value="0">No</b-form-radio>
                    </div>
                </div>

            </b-form-group>

            <b-form-group label="Chose Type" label-for="formrow-type-input" class="mb-3">
                <select v-model="type" class="form-select">
                    <option   value="email">Email</option>
                    <option   value="phone">Phone</option>
                    <option   value="text">Text</option>
                </select>

            </b-form-group>

            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="editCrmOptIn" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Submit</b-button>
        </template>
    </b-modal>
</template>